import React from 'react';

function EthereumIcon() {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10.5" cy="10" r="10" fill="#EFF0FB" />
            <path d="M5.5 9.8569L10.4998 2L15.4997 9.8569L10.4998 12.714L5.5 9.8569Z" fill="#7880E7" />
            <path d="M10.5 2L15.4998 9.8569L10.5 12.714V2Z" fill="#5C64C7" />
            <path d="M5.5 10.9282L10.4998 13.7853L15.4997 10.9282L10.4998 17.7137L5.5 10.9282Z" fill="#7880E7" />
            <path d="M10.4998 13.7856L15.4997 10.9285L10.4998 17.714V13.7856ZM5.5 9.85715L10.4998 7.71436L15.4997 9.85715L10.4998 12.7142L5.5 9.85715Z" fill="#5C64C7" />
            <path d="M10.5 7.71436L15.4998 9.85715L10.5 12.7142V7.71436Z" fill="#2A3192" />
        </svg>
    );
}

export default EthereumIcon;